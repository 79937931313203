import { fetchHeaders } from '../js/lib/api';

export interface PrideraiserShareButtonPostShareAction {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

export class PrideraiserShareButton extends HTMLElement {
  get url(): string {
    return this.getAttribute('url') || '';
  }

  set url(value: string) {
    this.setAttribute('url', value);
  }

  get text(): string {
    return this.getAttribute('text') || '';
  }

  set text(value: string) {
    this.setAttribute('text', value);
  }

  get shareTitle(): string {
    return this.getAttribute('share-title') || '';
  }

  set shareTitle(value: string) {
    this.setAttribute('share-title', value);
  }

  get postShareAction(): PrideraiserShareButtonPostShareAction | null {
    const value = this.getAttribute('post-share-action');
    if (!value) return null;
    return JSON.parse(value);
  }

  set postShareAction(value: PrideraiserShareButtonPostShareAction | null) {
    if (!value) {
      this.removeAttribute('post-share-action');
      return;
    }
    this.setAttribute('post-share-action', JSON.stringify(value));
  }

  connectedCallback() {
    if (!navigator.share || !this.url) {
      this.classList.add('unsupported');
      return;
    }

    const button = document.createElement('button');
    button.dataset.color = 'primary';
    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="1em" class="icon icon--share-from-square icon--share-from-square--regular"><use href="#icon--share-from-square--regular"></svg> Share`;
    this.append(button);
    button.addEventListener('click', () => {
      const opts: { url: string; text?: string; title?: string } = {
        url: this.url,
      };
      if (this.text) {
        opts['text'] = this.text;
      }
      if (this.shareTitle) {
        opts['title'] = this.shareTitle;
      }
      try {
        navigator.share(opts);
        if (this.postShareAction) {
          fetch(this.postShareAction.url, {
            method: this.postShareAction.method || 'GET',
            headers: fetchHeaders,
          });
        }
      } catch (e) {}
    });
  }
}

(() => {
  if (window.customElements.get('pr-share-button')) return;

  window.customElements.define('pr-share-button', PrideraiserShareButton);
})();
